/* src/components/AppBar.css */
.appbar {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    background: white;
    /*backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(10px);*/
}

.appbar-wrapper {
    margin-left: 20px;
    margin-right: 20px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}

.appbar-title {
    cursor: pointer;
    font-size: 18px;
}

.appbar-navigation button {
    background-color: transparent;
    border: none;
    color: #333;
    font-size: 16px;
    margin-left: 20px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.appbar-navigation button:hover {
    color: #333;
}

.menu-button {
    color: #333;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
}



/* Side menu styles */
.side-menu {
    position: fixed;
    top: 0;
    left: -100%; /* Initially hidden off the left */
    width: 80%; /* Side menu takes up half the screen */
    height: 100%;
    transition: left 0.3s ease; /* Smooth slide-in effect */
    z-index: 1000;
    -webkit-box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.1);
    background: white;
    /*backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);*/
}

.side-menu.open {
    left: 0; /* Slide in the menu */
}

.side-menu-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    padding: 20px;
}

.menu-top {
    padding: 20px;
}

.menu-middle {
    padding: 20px 20px 20px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.menu-bottom {
    padding: 0px 0px 40px 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.menu-middle button {
    border: none;
    background: none;
    margin-bottom: 20px;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    padding: 0;
}

.menu-bottom button {
    border: none;
    background: none;
    padding: 5px;
    margin: 0;
    color: #333;
    opacity: 0.7;
    font-size: 12px;
}

.menu-bottom div {
    border: none;
    background: none;
    padding: 10px 0 0 0;
    margin: 0;
    color: #333;
    opacity: 0.7;
    font-size: 10px;
    text-align: center;
    line-height: 1.4;
}

/* Blur overlay */
.blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px); /* Apply blur effect */
    z-index: 999; /* Below the side menu but above everything else */
}