/* src/components/ConfirmationModal.css */
.confirmation-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7); /* White backdrop with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(8px); /* Blurred background */
    z-index: 1000;
}

.confirmation-modal {
    padding: 20px;
    text-align: center;
    border-radius: 8px;
}

.modal-buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.cancel-button {
    background-color: #cccccc;
    color: #333;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    font-weight: 500;
}
