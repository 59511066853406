h1 {
  text-align: left;
  font-size: 24px;
  margin: 0 0 20px 0;
  font-weight: 500;
}

h2 {
  text-align: left;
  font-size: 18px;
  margin: 0 0 10px 0;
  font-weight: 500;
}

h3 {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.wrapper {
  width: 100%;
  line-height: 1.4;
  overflow-Y: scroll;
  min-height: 100vh;
}

.padding-top {
  height: 70px;
}

.wrapper1{
  padding: 0px 20px 20px 20px;
}

.form-group {
  width: 100%;
  position: relative;
  margin-top: 20px;
}

label {
  display: block;
  font-size: 16px;
  color: #333;
  font-weight: 500;
  margin-bottom: 10px;
}

.label-description {
  margin-top: 6px;
  margin-bottom: 20px;
  opacity: 0.7;
  font-size: 14px;
}

.input-wrapper {
  box-sizing: border-box;
  border: 2px solid rgba(0,0,0,0.3);
  padding: 8px 10px;
  border-radius: 10px;
  width: 100%;
}

input, textarea {
  border: none;
  outline: none;
  font-size: 16px;
  color: #000;
  background: none;
  font-weight: 500;
  width: 100%;
  font-family: inherit;
}

input::placeholder,
textarea::placeholder {
  color: #777;
  font-weight: 500;
  font-size: 16px;
}

textarea {
  resize: none;
  vertical-align: middle;
  overflow-Y: auto;
}

.action-button {
  background-color: #333;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  font-weight: 500;
  margin-top: 20px;
}

.action-button:hover {
  background-color: #555;
}

/* Tabs.css */
.tabs-container {
  width: 100%;
}

.tabs-container.loading {
  filter: blur(5px);
  opacity: 0.7;
  pointer-events: none; /* Prevent interaction while loading */
}

.tabs-scroll-container {
  padding: 0 20px 0 20px;
  transition: filter 0.3s ease, opacity 0.3s ease;
}

.tabs-scroll {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /*overflow-x: auto; /* Allow horizontal scrolling */
  /*scrollbar-width: none; /* Hide scrollbar in Firefox */
  width: 100%;
  margin: 0 0 10px 0px;
  position: relative;
  
  /*mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent); /* Gradient fade */
  /*-webkit-mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);*/
  scroll-behavior: smooth;
}

.tabs-scroll::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers (Chrome, Safari) */
}

.tab-item {
  flex-shrink: 0;
  padding: 6px 10px;
  color: #333;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  background: none;
  font-weight: 500;
  border: 1px solid #333;
}

.tab-item.active {
  background-color: #333;
  color: white;
  font-weight: 500;
  border: 1px solid #333;
}

.tab-item:hover {
  background-color: #333;
  color: white;
}

.tab-item:focus {
  outline: none; /* Prevent default focus outline */
}

.tab-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.tab-block {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px 16px;
  border-radius: 10px;
  transition: transform 0.3s ease;
  width: 100%;
  cursor: pointer;
  border: 1px solid #eee;
  background: #eee;
  transition: all 0.3s ease-in-out;
}

.tab-block:hover {
  transform: translateY(-5px);
}

.tab-block-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #000;
  font-weight: 500;
}

.tab-block-icon {
  margin-right: 10px;
}

.tab-block h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
  font-weight: 500;
}

.tab-block p {
  margin: 5px 0 0;
  color: #666;
}

.gradient-button {
  background: linear-gradient(90deg, #CBEB5B, #FFB770, #FD8CFF); /* Gradient from blue to purple */
  color: white;
  padding: 10px; /* Adjust padding for bigger button */
  font-size: 18px; /* Increase font size */
  font-weight: bold; /* Bold text for prominence */
  border: none;
  border-radius: 10px; /* Large border radius for pill shape */
  display: inline-flex; /* Align icon and text in a row */
  align-items: center; /* Center the icon and text vertically */
  justify-content: center; /* Center the content horizontally */
  cursor: pointer;
  transition: transform 0.2s ease-in-out; /* Add a hover effect */
  margin-top: 20px;

}

.gradient-button-icon {
  margin-left: 10px; /* Space between the text and icon */
  font-size: 24px; /* Adjust icon size */
}