.bubble-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 20px;
    width: 100%;
}

.thingy-img {
    width: 30px;
    padding-right: 10px;
}

.bubble-text {
    background: black;
    color: white;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size: 12px;
    line-height: 1.4;
    padding: 10px 16px;
    border-radius: 10px;
    margin-bottom: 30px;
    word-wrap: break-word;  /* Prevents words from breaking awkwardly */
    white-space: pre-wrap;
}

.streamed-response {
    white-space: pre-wrap; /* Preserve whitespace and line breaks */
    word-wrap: break-word;
    font-family: monospace; /* Ensures fixed-width font for better readability */
    font-size: 12px;
  }

  .dropdown-button-wrapper {
    background-color: #ffff;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
}

.dropdown-button {
    color: #333;
    font-weight: 500;
    padding: 6px 4px 6px 10px;
    border-radius: 10px;
    font-size: 16px;
    border: none;
    width: 100%;
    text-align: left;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    background: #eee;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;

}

.dropdown-text-wrapper {
    width: 100%;

}

.dropdown-text {
    white-space: nowrap;
    overflow: hidden;
    mask-image: linear-gradient(to right, rgb(255, 255, 255) 70%, transparent 100%);
    -webkit-mask-image: linear-gradient(to right, rgb(255, 255, 255) 70%, transparent 100%);
}

.dropdown-button-wrapper:hover, .dropdown-button-wrapper:focus {
    background-color: #ffff;
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
}

/* Dropdown list */
.dropdown-list {
    position: absolute;
    background-color: #eee;
    top: 100%;
    left: 0;
    border-radius: 10px;
    margin-top: 0px;
    width: 100%;
    z-index: 1;
    padding: 0;
    list-style: none;
    /*-webkit-box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.1);*/

}

.dropdown-item {
    padding: 10px 16px;
    
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 10px;
    mask-image: linear-gradient(to right, rgb(255, 255, 255) 70%, transparent 100%);
    -webkit-mask-image: linear-gradient(to right, rgb(255, 255, 255) 70%, transparent 100%);
    white-space: nowrap;
    overflow: hidden;
}

.dropdown-item:hover {
    background-color: #e0e0e0;
}

/* Close dropdown when an item is selected */
.custom-dropdown .dropdown-list li.selected {
    background-color: #333;
    color: white;
    width: 100%;
}

/* Dropdown button styling */
.custom-dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-top: 10px;
}