.status-wrapper {
  margin-bottom: 30px;
}
.status-bar {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 30px 20px;
}

.status-item {
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 1.4;
  gap: 10px;
  padding: 4px 8px;
}

.status-item.clickable {
  cursor: pointer;
}

.status-icon {
  width: 30px;
}

.status-description {
  margin-top: 4px;
  white-space: pre-line;
  font-size: 12px;
  text-align: left;
}

.active {
  color: black;
  font-weight: 500;
  background: #eee;;
  border-radius: 10px;
}

.inactive {
  opacity: 0.4;
  font-weight: 500;
  border-radius: 10px;
}

.fading-line {
  width: 100%; /* Full width */
  height: 1px; /* Line thickness */
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0) 100%);
}
