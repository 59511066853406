@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.onboarding-slider {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  height: 100vh; /* Full screen height */
  background-color: rgb(34,34,34);
}

.slick-slide {
  height: 100vh;
}

.slide-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px;
  text-align: center;
  color: white;
}

.slide-description {
  font-size: 12px;
  margin-bottom: 0 10px 10px 0;
  padding: 0 16px;
  color: white;
}

.fixed-buttons {
  position: fixed;
  bottom: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  box-sizing: border-box;
}

.skip-button {
  background-color: transparent;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  flex: 1;
  margin: 0 5px;
}

.next-button,
.start-button {
  background-color: white;
  color: #333;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  flex: 1;
  margin: 0 5px;
}

.start-button {
  flex: 1;
}

.slick-dots {
  bottom: 100px;
}

.slick-dots li {
  margin: 0;
}

.slick-dots li button:before {
  font-size: 10px; /* Adjust size if needed */
  color: white;    /* Set the color to white */
  opacity: 1;      /* Ensure the dots are fully visible */
}

.slick-dots li.slick-active button:before {
  color: #888888;    /* Ensure the active dot is also white */
}

.slide-images {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.slide-image {
  height: 300px;
}
