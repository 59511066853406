/* Grid container */
.projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns in a row */
    gap: 20px; /* Space between grid items */
}

/* Grid item styling */
.grid-item {
    background-color: #eee;
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
    color: #333;
}

.grid-item:hover {
    transform: translateY(-5px);
    background-color: #e0e0e0;
}

.grid-project-name {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
}

.grid-project-description {
    white-space: nowrap;
    overflow: hidden;
    mask-image: linear-gradient(to right, black 70%, transparent 100%);
    -webkit-mask-image: linear-gradient(to right, black 70%, transparent 100%);
}

/* Styling for + New Project square */
.new-project {
    background-color: #333;
    color: white;
}

.new-project:hover {
    background-color: #555;
}
