.tariffs-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .tariffs-wrapper {
        flex-direction: column;
        justify-content: flex-start;
    }
}