.tariffs-slider {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    color: #333;
}

.bold-text {
    font-size: 22px;
    font-weight: 500;
}

.button-set {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

  .custom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    background: green;
    padding: 0!important;
  }

  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    border-radius: 20px;
    border: none;
    background: #f0f0f0;
  }
  
  .custom-range::-moz-range-track {
    width: 100%;
    height: 10px;
    border-radius: 20px;
    border: none;
    background: #f0f0f0;
  }
  
  .custom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    background: #333;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -10px;
  }
  
  .custom-range::-moz-range-thumb {
    height: 30px;
    width: 30px;
    background: #333;
    border-radius: 50%;
    cursor: pointer;
  }

  .inactive-slider input[type="range"]::-webkit-slider-thumb {
    background: #f0f0f0; /* Change thumb color when a button is pressed */
  }
  
  .inactive-slider input[type="range"]::-moz-range-thumb {
    background: #f0f0f0; /* Change thumb color when a button is pressed for Firefox */
  }

  .inactive-slider input[type="range"]::-webkit-slider-runnable-track {
    background: #f0f0f0;
  }
  
  .inactive-slider input[type="range"]::-moz-range-track {
    background: #f0f0f0;
  }

.button-group.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.button-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.traffic-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  transition: background-color 0.1s;
}

.traffic-button.inactive {
  background-color: #0088cc;
  color: white;
}

.description-text {
  font-size: 14px;
  color: #6a6a6a;
}

.tokens-container {
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  margin-bottom: 50px;
}

.token-card {
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid rgba(0,0,0,0.2)
}

.token-card p {
  margin: 0;
}

.tariffs-tab-item {
  flex-shrink: 0;
  padding: 6px 10px;
  color: #555;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
  background: none;
  font-weight: 600;
  border: 1px solid #777;
}

.tariffs-tab-item.active {
  background-color: #333;
  color: white;
  font-weight: 500;
  border: 1px solid #333;
}

.tariffs-tab-item:hover {
  background-color: #333;
  color: white;
}

.tariffs-tab-item:focus {
  outline: none; /* Prevent default focus outline */
}