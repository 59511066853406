.project-form {
    display: flex;
    flex-direction: column;
  }

.project-buttons {
    margin-top: 10px;
}

.project-button {
    display: inline-block;
    margin-right: 10px;
    padding: 5px 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.project-button:hover {
    background-color: #ddd;
}